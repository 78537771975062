package com.damda.webapp.views.core

import kotlinx.browser.localStorage
import kotlin.js.Date
import kotlin.random.Random

object UserIdManager {
    private const val USER_ID_KEY = "user_id"

    fun getUserId(): String {
        return localStorage.getItem(USER_ID_KEY) ?: generateAndSaveNewUserId()
    }

    private fun generateAndSaveNewUserId(): String {
        val newId = generateUniqueId()
        localStorage.setItem(USER_ID_KEY, newId)
        return newId
    }

    private fun generateUniqueId(): String {
        val timestamp = Date().getTime()
        val randomNum = Random.nextInt(0, 1000000)
        return "user_${timestamp}_$randomNum"
    }

    fun clearUserId() {
        localStorage.removeItem(USER_ID_KEY)
    }
}