package com.damda.webapp

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.damda.invitation.core.ComposeWindowWidthSize
import com.damda.invitation.ui.viewmodels.ScreenState
import com.damda.invitation.ui.viewmodels.WeddingScreen
import com.damda.webapp.views.screens.GalleryWebScreen
import com.damda.webapp.views.screens.MainWebScreen
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposableInBody
import org.w3c.dom.events.Event

fun main() {
    renderComposableInBody {
        val screenState by remember { mutableStateOf(ScreenState()) }
        var windowWidthSize by remember { mutableStateOf(ComposeWindowWidthSize.Small) }
        DisposableEffect(Unit) {
            val resizeListener: (Event) -> Unit = {
                val newWindowSize = when {
                    window.innerWidth < 600 -> ComposeWindowWidthSize.Small
                    window.innerWidth < 1200 -> ComposeWindowWidthSize.Medium
                    else -> ComposeWindowWidthSize.Large
                }
                windowWidthSize = newWindowSize
            }

            window.addEventListener("resize", resizeListener)
            resizeListener(Event(""))

            onDispose {
                window.removeEventListener("resize", resizeListener)
            }
        }

        WeddingRouter(screenState)
    }
}

@Composable
fun WeddingRouter(
    cartState: ScreenState
) {
    val onScreenEvent by cartState.onScreenEvent
    onScreenEvent.getContentIfNotHandled()?.let {
        when (it) {
            is WeddingScreen.Home -> {
                MainWebScreen(ComposeWindowWidthSize.Medium) { galleryItem, allItems ->
                    cartState.changeScreen(WeddingScreen.Gallery(galleryItem, allItems))
                }
                // 홈 화면으로 돌아왔을 때 초기화 작업
                DisposableEffect(Unit) {
                    callInitializeObserverIfExists() // 메서드 직접 호출
                    onDispose { }
                }
            }
            is WeddingScreen.Gallery -> {
                GalleryWebScreen(it.item, it.allItems, onBackArrowClick = {
                    cartState.changeScreen(WeddingScreen.Home)
                })
            }
            else -> {
            }
        }
    }
}

fun callInitializeObserverIfExists() {
    val jsFunctionExists = js("typeof initializeObserver === 'function'")
    if (jsFunctionExists as Boolean) {
        js("initializeObserver()")
    } else {
        console.log("initializeObserver 함수가 로드되지 않았습니다.")
    }
}