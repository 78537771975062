@file:OptIn(ExperimentalComposeWebApi::class)

package com.damda.webapp.views.sections.main

import androidx.compose.runtime.Composable
import com.damda.invitation.themes.Colors
import com.damda.webapp.views.themes.toCssColor
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.gridTemplateColumns
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.overflow
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.selectors.Nth
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.top
import org.jetbrains.compose.web.css.transform
import org.jetbrains.compose.web.css.unaryMinus
import org.jetbrains.compose.web.css.whiteSpace
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun WeddingCalendar() {
    Style(CalendarStyles)
    Div(attrs = {
        style {
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.Center)
            alignItems(AlignItems.Center)
            width(100.percent)
            height(100.percent)  // 부모 div의 높이가 정해져 있다고 가정
        }
    }) {
        Div(attrs = { classes(CalendarStyles.calendar) }) {
            Div(attrs = { classes(CalendarStyles.month) }) {
                Text("2024년 10월")
            }
            Div(attrs = { classes(CalendarStyles.weekdays) }) {
                listOf("일", "월", "화", "수", "목", "금", "토").forEachIndexed { index, day ->
                    Div(attrs = {
                        classes(CalendarStyles.weekdayCell)
                        if (index == 0) classes(CalendarStyles.sundayCell)
                    }) { Text(day) }
                }
            }
            Div(attrs = { classes(CalendarStyles.days) }) {
                repeat(2) { Div(attrs = { classes(CalendarStyles.dayCell) }) } // 첫 주 빈 칸
                (1..31).forEach { day ->
                    Div(attrs = {
                        classes(CalendarStyles.dayCell)
                        if (day % 7 == 6) classes(CalendarStyles.sundayCell)
                        if (day == 20) classes(CalendarStyles.highlight)
                    }) {
                        Text("$day")
                        if (day == 20) {
                            Span(attrs = { classes(CalendarStyles.weddingDay) }) {
                                Text("결혼하는 날")
                            }
                        }
                    }
                }
            }
        }
    }

}


object CalendarStyles : StyleSheet() {
    val calendar by style {
        backgroundColor(Color.white)
        borderRadius(12.px)
        property("box-shadow", "0 0 10px rgba(0, 0, 0, 0.1)")
        overflow("hidden")
        width(300.px)
    }

    val month by style {
        backgroundColor(Color("#FFB6C1"))
        color(Color.white)
        padding(10.px)
        textAlign("center")
        fontSize(16.px)
    }

    val weekdays by style {
        display(DisplayStyle.Flex)
        backgroundColor(Color("#f1f1f1"))
    }

    val weekdayCell by style {
        width(40.px)
        padding(10.px)
        textAlign("center")
    }

    val days by style {
        display(DisplayStyle.Grid)
        gridTemplateColumns("repeat(7, 1fr)")
    }

    val dayCell by style {
        width(40.px)
        height(40.px)
        display(DisplayStyle.Flex)
        justifyContent(JustifyContent.Center)
        alignItems(AlignItems.Center)
        padding(5.px)
        boxSizing("border-box")
    }

    val sundayCell by style {
        color(Color.red)
    }

    val highlight by style {
        backgroundColor(Colors.pinkColor.toCssColor())
        property("color", "white !important")  // important 적용
        borderRadius(50.percent)
        fontWeight("bold")
        position(Position.Relative)
        width(34.px)
        height(34.px)
        display(DisplayStyle.Flex)
        justifyContent(JustifyContent.Center)
        alignItems(AlignItems.Center)
        margin(3.px)
    }

    val weddingDay by style {
        position(Position.Absolute)
        top(100.percent)
        left(50.percent)
        transform {
            translateX((-50).percent)
        }
        fontSize(8.px)
        whiteSpace("nowrap")
        color(Color("#333"))
        marginTop(2.px)
    }
}