package com.damda.invitation.firebases

import com.damda.invitation.data.FirebaseChatMessage
import com.damda.invitation.data.toChatMessageDomain
import com.damda.invitation.domain.ChatMessageDomain
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.HttpResponse
import io.ktor.http.contentType
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json


@Serializable
data class FirestoreResponse(
    val documents: List<FirebaseChatMessage>
)

class FirebaseClient(private val config: FirebaseConfig) {
    // HTTP 클라이언트 설정
    private val client = HttpClient {
        install(ContentNegotiation) {
            json(Json { ignoreUnknownKeys = true })
        }
    }

    private fun getFirestoreUrl(path: String): String {
        return "https://firestore.googleapis.com/v1/projects/${config.projectId}/databases/(default)/documents/$path"
    }

    suspend fun addChatMessage(chatMessageDomain: ChatMessageDomain) {
        val url = getFirestoreUrl("chatMessages")
        client.post(url) {
            contentType(io.ktor.http.ContentType.Application.Json)
            setBody(mapOf(
                "fields" to mapOf(
                    "content" to mapOf("stringValue" to chatMessageDomain.content),
                    "profileUrl" to mapOf("stringValue" to chatMessageDomain.profileUrl),
                    "profileName" to mapOf("stringValue" to chatMessageDomain.profileName),
                    "createdTime" to mapOf("stringValue" to chatMessageDomain.createdTime),
                    "step" to mapOf("stringValue" to chatMessageDomain.step),
                    "score" to mapOf("stringValue" to chatMessageDomain.score)
                )
            ))
        }
    }

    suspend fun getChatMessages(): List<ChatMessageDomain> {
        val url = getFirestoreUrl("chatMessages")
        val response: HttpResponse = client.get(url)
        val body: FirestoreResponse = response.body()
        return body.documents.map { it.toChatMessageDomain() }
    }
}