package com.damda.webapp.views.layouts

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.StyleScope
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.minHeight
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.right
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.textDecoration
import org.jetbrains.compose.web.css.top
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun AppBar(title: String, isShowBackButton:Boolean = true, onBackArrowClick: () -> Unit, modifier: StyleScope.() -> Unit = {}) {
    var expanded by remember { mutableStateOf(false) }  // 메뉴 확장 상태
    var saved by remember { mutableStateOf(false) }  // 저장 여부 상태

    Div(
        attrs = {
            style {
                width(100.percent)
                height(64.px)
                minHeight(64.px)
                display(DisplayStyle.Flex)
                alignItems(AlignItems.Center)
                justifyContent(JustifyContent.SpaceBetween)
                paddingLeft(16.px)
                paddingRight(26.px)
                backgroundColor(Color.transparent)
                boxSizing("border-box")
                modifier()
            }
        }
    ) {
        if (isShowBackButton) {
            Button(
                attrs = {
                    style {
                        backgroundColor(Color.transparent)
                        border(0.px)
                    }
                    onClick {
                        onBackArrowClick()
                    }
                }
            ) {
                Img(src = "ic_arrow_back.svg", attrs = { style { width(16.px); height(16.px) } })
            }
        }
        Span(
            attrs = {
                style {
                    fontSize(20.px)
                    fontWeight(500) // semibold
                    color(Color("#333333"))
                    textAlign("center")
                    flexGrow(1)
                }
            }
        ) {
            Text(title)
        }
        Button(
            attrs = {
                style {
                    backgroundColor(Color.transparent)
                    border(0.px)
                }
                onClick {
                    //expanded = !expanded
                }
            }
        ) {
            Img(src = "ic_menu.svg", attrs = { style { width(4.px); height(16.px) } })
        }
        var expanded by remember { mutableStateOf(false) }  // 메뉴 확장 상태
        var saved by remember { mutableStateOf(false) }  // 저장 여부 상태

        // 드롭다운 메뉴
        if (expanded) {
            Div(
                attrs = {
                    style {
                        position(Position.Absolute)
                        top(20.px)  // 아이콘 바로 아래에 위치
                        right(0.px)  // 아이콘 오른쪽 정렬
                        backgroundColor(Color.white)
                        borderRadius(4.px)
                        property("box-shadow", "0px 8px 16px rgba(0, 0, 0, 0.2)")
                        property("z-index", "1")
                    }
                }
            ) {
                // "저장하기" 항목
                A(
                    href = "#",
                    attrs = {
                        classes("menu-item")  // CSS 클래스 적용
                        onClick {
                            // saveData()  // 저장 기능 실행
                            expanded = false  // 메뉴 닫기
                            saved = true  // 저장 상태 업데이트
                        }
                    }
                ) {
                    Text("저장하기")
                }
            }
        }

        // 저장 상태 메시지
        if (saved) {
            Div(
                attrs = {
                    style {
                        marginTop(10.px)
                        color(Color.green)
                    }
                }
            ) {
                Text("저장되었습니다!")
            }
        }
    }
}

@Composable
fun ChatTopBar(title: String) {
    // 채팅 제목 및 참여자 정보
    Div({
        style {
            display(DisplayStyle.Flex)
            alignItems(AlignItems.Center)
            padding(16.px)
            fontWeight("bold")
            fontSize(20.px)
            // borderBottom(1.px, LineStyle.Solid, Color.Gray)
        }
    }) {
        Text(title)
    }
}

@Composable
fun ChatStatusBar(currentTime: String) {
    // 상단 상태바
    Div({
        style {
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.SpaceBetween)
            padding(8.px)
            backgroundColor(Color("#90a4ae"))
            color(Color.white)
        }
    }) {
        Div {
            Text("SKT $currentTime")
        }
        Div {
            Text("LTE 999%")
        }
    }
}