package com.damda.webapp.views.sections.main

import androidx.compose.runtime.Composable
import com.damda.invitation.ui.viewmodels.GalleryItem
import com.damda.webapp.views.images.ImageGallery
import com.damda.webapp.views.layouts.Card
import com.damda.webapp.views.layouts.JSSpacer
import com.damda.webapp.views.layouts.VerticalLayout
import com.damda.webapp.views.screens.images
import com.damda.webapp.views.themes.strokeBoldCardStyle
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Text

@Composable
fun GalleryCardSection(
    onClickGallery: (GalleryItem, List<String>) -> Unit
) {
    VerticalLayout {
        Div({
            classes("fade-section")
        }) {
            H2 (
                {
                    style {
                        display(DisplayStyle.Flex)
                        flexDirection(FlexDirection.Column)
                        alignItems(AlignItems.Center)
                        justifyContent(JustifyContent.Center)
                        width(100.percent)
                    }
                }
            ) {
                Text("갤러리 \uD83D\uDDBC\uFE0F")
            }
            JSSpacer(height = 40.px)
            Card(modifier = {
                strokeBoldCardStyle()
                height(100.percent)
                boxSizing("border-box")
            }) {
                ImageGallery(images, onClickGallery)
            }
        }

    }
}