package com.damda.webapp.views.sections.main

import androidx.compose.runtime.Composable
import com.damda.invitation.themes.Colors
import com.damda.webapp.views.screens.WeddingAnnouncementStyle
import com.damda.webapp.views.themes.matchParentStyle
import com.damda.webapp.views.themes.toCssColor
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.transform
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun IntroSection() {
    Style(WeddingAnnouncementStyle)
    Div({
        classes(WeddingAnnouncementStyle.container)
        style {
            backgroundColor(Colors.backgroundColor.toCssColor())
        }
    }) {
        Div({ classes(WeddingAnnouncementStyle.imageContainer) }) {
            Img("thumbnail_gallery3.webp", attrs = {
                style {
                    matchParentStyle()
                    transform { scale(1) }
                    property("object-fit", "cover")
                    property("object-position", "55% center")
                }
            })
            Div({ classes(WeddingAnnouncementStyle.dimOverlay) })
        }
        Div({ classes(WeddingAnnouncementStyle.textContainer) }) {
            H1({
                classes(WeddingAnnouncementStyle.title)
            }) {
                Text("Hello, World!")
            }
            P({ classes(WeddingAnnouncementStyle.names) }) {
                Span({ classes(WeddingAnnouncementStyle.highlight) }) { Text("문병학") }
                Span({ classes(WeddingAnnouncementStyle.loveText) }) { Text(" ♥ ") }
                Span({ classes(WeddingAnnouncementStyle.highlight) }) { Text("이홍주") }
            }
            Div {
                P {
                    Text("2024년 10월 20일, 오전 11시 30분")
                    Br()
                    Text("결혼합니다.")
                }
                P {
                    Text("장소: ")
                    Text("분당앤스퀘어 4층, 아모르홀")
                }
            }
        }
    }
}