package com.damda.invitation.data

import com.damda.invitation.domain.ChatMessageDomain
import kotlinx.serialization.Serializable

@Serializable
data class FirebaseChatMessage(
    val fields: Map<String, FirebaseField>
)

@Serializable
data class FirebaseField(
    val stringValue: String
)

fun FirebaseChatMessage.toChatMessageDomain(): ChatMessageDomain {
    val content = fields["content"]?.stringValue ?: ""
    val profileId = fields["profileId"]?.stringValue ?: ""
    val profileUrl = fields["profileUrl"]?.stringValue ?: ""
    val profileName = fields["profileName"]?.stringValue ?: ""
    val createdTime = fields["createdTime"]?.stringValue ?: ""
    val step = fields["step"]?.stringValue ?: ""
    val score = fields["score"]?.stringValue ?: ""

    return ChatMessageDomain(content, profileId, profileUrl, profileName, createdTime, step, score)
}