package com.damda.webapp.views.chats

import androidx.compose.runtime.Composable
import com.damda.webapp.views.images.JSRoundedImage
import com.damda.webapp.views.layouts.JSRow
import com.damda.webapp.views.layouts.JSSpacer
import com.damda.webapp.views.texts.StyledText
import com.damda.webapp.views.texts.TextSectionPresentation
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div

@Composable
fun ChatProfile(presentation: TextSectionPresentation) {
    JSRow(
        alignItem = AlignItems.Stretch,
        justifyContents = JustifyContent.FlexStart,
        modifier = {
            width(100.percent)
            height(auto)
            paddingLeft(16.px)
            paddingRight(16.px)
        },
        content = {
            JSRoundedImage(
                url = presentation.imageUrl,
                borderRadius = 14.px,
                size = 40,
                borderWidth = 0.px
            )
            JSSpacer(width = 8.px)
            Div(attrs = {
                style {
                    marginTop(6.px)
                }
            }) {
                StyledText(
                    text = presentation.title,
                    fontSize = 14.px,
                    fontWeight = "",
                    maxLines = 1
                )
            }
        }
    )
}