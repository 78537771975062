package com.damda.webapp.views.chats

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.damda.invitation.presentation.QuizMessage
import com.damda.webapp.views.core.UserIdManager
import com.damda.webapp.views.layouts.JSSpacer
import kotlinx.browser.document
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.coroutines.suspendCancellableCoroutine
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.maxWidth
import org.jetbrains.compose.web.css.minWidth
import org.jetbrains.compose.web.css.overflow
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.whiteSpace
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import kotlin.js.Date

data class Question(
    val text: String,
    val options: List<String>,
    val answerIndex: Int
)

val questions = listOf(
    Question("홍주와 병학이가 사는 곳은?", listOf("기흥", "분당", "수지", "정자"), 0),
    Question("홍주와 병학이의 직업은?", listOf("그림자", "화가", "개발자", "자영업자"), 2),
    Question("홍주와 병학이의 결혼식 날짜는?", listOf("8월 20일", "10월 20일, 오전 11시 30분", "11월 18일", "3월 12일"), 1),
    Question("홍주와 병학이가 1년 동안 가장 많이 먹은 음식은?", listOf("회", "덮밥", "파스타", "불고기"), 2),
    Question("홍주와 병학이가 가장 좋아하는 유튜브는?", listOf("침착맨", "슈카월드", "빠더너스", "백종원"), 0)
)

@Composable
fun QuizGame(
    onSendQuizMessage: (QuizMessage) -> Unit,
    notifyDialog: () -> Unit,
    onClick: () -> Unit
) {
    var currentQuestionIndex by remember { mutableStateOf(0) }
    var userAnswers by remember { mutableStateOf(List(questions.size) { -1 }) }
    var showResult by remember { mutableStateOf(false) }

    Style(AppStylesheet)

    Div({
        classes(AppStylesheet.quizContainer)
    }) {
        H3({ classes(AppStylesheet.title) }) { Text("우정 테스트 게임 결과") }

        if (!showResult) {
            Div({ classes(AppStylesheet.progress) }) {
                Text("문제 ${currentQuestionIndex + 1} / ${questions.size}")
            }

            Div({ classes(AppStylesheet.question) }) {
                Text(questions[currentQuestionIndex].text)
            }

            Div({ classes(AppStylesheet.options) }) {
                questions[currentQuestionIndex].options.forEachIndexed { index, option ->
                    Button({
                        classes(AppStylesheet.option)
                        if (userAnswers[currentQuestionIndex] == index) {
                            classes(AppStylesheet.selected)
                        }
                        onClick {
                            userAnswers = userAnswers.toMutableList().also {
                                it[currentQuestionIndex] = index
                            }
                        }
                    }) {
                        Text(option)
                    }
                }
            }

            Div({ classes(AppStylesheet.navigation) }) {
                Button({
                    classes(AppStylesheet.button)
                    attr("disabled", (currentQuestionIndex == 0).toString())
                    onClick { currentQuestionIndex-- }
                    style {
                        if (currentQuestionIndex == 0) {
                            backgroundColor(Color("#cccccc"))
                            cursor("not-allowed")
                        }
                    }
                }) {
                    Text("이전")
                }

                Button({
                    classes(AppStylesheet.button)
                    onClick {
                        if (currentQuestionIndex < questions.size - 1) {
                            currentQuestionIndex++
                        } else {
                            showResult = true
                        }
                        onClick()
                    }
                    style {
                        property("transition", "background-color 0.3s")
                    }
                    attr("onmouseover", "this.style.backgroundColor='#45a049'")
                    attr("onmouseout", "this.style.backgroundColor='#4CAF50'")
                }) {
                    Text(if (currentQuestionIndex == questions.size - 1) "결과 보기" else "다음")
                }
            }
        } else {
            val score = userAnswers.zip(questions).count { (answer, question) ->
                answer == question.answerIndex
            }

            Div({
                classes(AppStylesheet.result)
                id("capture-area")
                style {
                    backgroundColor(Color("#f4f4f4"))
                    borderRadius(12.px)
                    width(100.percent)
                    textAlign("left")
                    overflow("hidden") // 부모 요소에서 오버플로우 설정
                    property("box-shadow", "0px 2px 4px rgba(0, 0, 0, 0.1)")
                }
            }) {
                Div(
                    {
                        style {
                            width(100.percent)
                            height(60.percent)
                            display(DisplayStyle.Flex)
                            justifyContent(JustifyContent.Center)
                            alignItems(AlignItems.Center)
                        }
                    }
                ) {
                    Img(
                        src = when {
                            score == 5 -> "score5.jpg"
                            score >= 3 -> "score3.jpg"
                            score >= 1 -> "score1.jpg"
                            else -> "score0.jpg"
                        },
                        attrs = {
                            classes(AppStylesheet.character)
                        }
                    )
                }
                Div({
                    style {
                        fontSize(20.px)
                        fontWeight(600)
                        marginTop(16.px)
                        marginLeft(16.px)
                        marginRight(16.px)
                    }
                }) {
                    Text("점수: ${score * 20}점")
                }

                Div({
                    style {
                        fontWeight(600)
                        fontSize(20.px)
                        marginLeft(16.px)
                        marginRight(16.px)
                    }
                }) {
                    Text(
                        when {
                            score == 5 -> "결혼 엔딩!"
                            score >= 3 -> "절친 엔딩!"
                            score >= 1 -> "너의 목소리가 들려 엔딩!"
                            score == 0 -> "개발자 엔딩!"
                            else -> " - 다음에 더 잘할 수 있을 거예요."
                        }
                    )
                }

                Div({
                    style {
                        paddingTop(12.px)
                        paddingLeft(16.px)
                        paddingRight(16.px)
                        paddingBottom(24.px)
                        width(95.percent)
                        maxWidth(95.percent)
                    }
                }) {
                    Div({
                        style {
                            fontSize(14.px)
                            fontWeight(400)
                            width(95.percent)
                            maxWidth(95.percent)
                            whiteSpace("normal") // 기본 줄바꿈 동작
                            property("word-break", "break-word") // 긴 단어 줄바꿈
                            overflow("visible") // 넘치는 텍스트가 숨겨지지 않고 표시되도록 함
                        }
                    }) {
                        Text(
                            when {
                                score == 5 -> "모든 문제를 맞힌 우리는 꽤 소울메이트 관계입니다. 함정 문제까지 모두 피해서 맞춘 당신, 앞으로도 우리 함께 해피엔딩을 그립시다."
                                score >= 3 -> "이 정도 알고 있으면 언제든 소울메이트가 될 수 있는 관계입니다. 약간씩 모를 때 더 돈독한 관계가 형성될 수 있다고 하네요. 우리 앞으로 더 친하게 지내봐요!"
                                score >= 1 -> "하하와 정형돈과 같은 관계입니다. 어색할 것 같다구요? 아뇨, 우리는 앞으로 친해질 일만 남은 관계입니다. 저는 당신의 목소리가 들리는데, 당신은 어떤가요?"
                                score == 0 -> "장난꾸러기 관게입니다. 모두 틀린 것은 모두 맞춘 것과 같다라고 볼 수 있죠. 고도의 집중력을 발휘해 여러번 테스트 해야만 이곳에 올 수 있습니다. 당신 개발자 잘 어울립니다!"
                                else -> " - 다음에 더 잘할 수 있을 거예요."
                            }
                        )
                    }
                }
            }
            Br()
            JSSpacer(height = 12.px)
            Div({ classes(AppStylesheet.navigation) }) {
                Button({
                    classes(AppStylesheet.button)
                    onClick {
                        MainScope().launch {
                            onSendQuizMessage(
                                QuizMessage(
                                    "2",
                                    score.toString(),
                                    saveAsImage(score, Date().getTime().toString(), false) ?: "",
                                    "",
                                    UserIdManager.getUserId(),
                                    "empty",
                                    "",
                                    Date().getTime().toString()
                                )
                            )
                        }

                    }
                    style {
                        padding(12.px, 2.px)
                        width(100.percent)
                        property("transition", "background-color 0.3s")
                    }
                    attr("onmouseover", "this.style.backgroundColor='#45a049'")
                    attr("onmouseout", "this.style.backgroundColor='#4CAF50'")
                }) {
                    Text("자랑하기")
                }
            }
            Div({ classes(AppStylesheet.navigation) }) {
                Button({
                    classes(AppStylesheet.button)
                    onClick {
                        MainScope().launch {
                            saveAsImage(score, currentTime = Date().getTime().toString(), true)
                        }
                    }
                    style {
                        padding(12.px, 2.px)
                        width(100.percent)
                        property("transition", "background-color 0.3s")
                    }
                    attr("onmouseover", "this.style.backgroundColor='#45a049'")
                    attr("onmouseout", "this.style.backgroundColor='#4CAF50'")
                }) {
                    Text("이미지로 저장하기")
                }
            }

        }
    }
}

object AppStylesheet : StyleSheet() {
    val quizContainer by style {
        maxWidth(400.px)
        margin(0.px)
        padding(12.px)
        backgroundColor(Color.white)
    }

    val title by style {
        color(Color("#333"))
        textAlign("center")
    }

    val progress by style {
        textAlign("center")
        color(Color("#666"))
        marginBottom(10.px)
    }

    val question by style {
        marginBottom(20.px)
    }

    val options by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
    }

    val option by style {
        margin(5.px, 0.px)
        padding(10.px)
        backgroundColor(Color("#e9e9e9"))
        border(0.px)
        borderRadius(4.px)
        cursor("pointer")
    }

    val selected by style {
        backgroundColor(Color("#4CAF50"))
        color(Color.white)
    }

    val navigation by style {
        display(DisplayStyle.Flex)
        justifyContent(JustifyContent.SpaceBetween)
        marginTop(20.px)
    }

    val button by style {
        padding(10.px, 20.px)
        border(0.px)
        borderRadius(4.px)
        backgroundColor(Color("#4CAF50"))
        color(Color.white)
        cursor("pointer")
    }

    val result by style {
        marginTop(20.px)
        flexDirection(FlexDirection.Column)
        alignItems(AlignItems.Start)
    }

    val character by style {
        maxWidth(100.percent)
        minWidth(100.percent)
    }
}

// html2canvas Promise를 Kotlin 코루틴으로 변환
suspend fun saveAsImage(score: Int, currentTime: String, isSaveLocalFile: Boolean): String? {
    val element = document.getElementById("capture-area")
    if (element == null) {
        console.error("캡처할 요소를 찾을 수 없습니다.")
        return null
    }

    return try {
        // html2canvas Promise를 Kotlin의 dynamic으로 처리
        val canvas = suspendCancellableCoroutine<dynamic> { continuation ->
            html2canvas(element).then { result ->
                continuation.resume(result)
            }.catch { error ->
                continuation.resumeWithException(error)
            }
        }

        // canvas 객체에서 toDataURL을 호출하여 이미지를 생성
        val imgData = canvas.toDataURL("image/png") as String

        // 이미지 다운로드 처리 (선택적)
        val link = document.createElement("a")
        link.setAttribute("href", imgData)
        link.setAttribute("download", "${currentTime}_score_$score.png")
        if (isSaveLocalFile) {
            link.asDynamic().click() // 동적으로 링크 클릭
        }


        println("이미지 저장 완료: $imgData")
        imgData // 생성된 이미지 데이터 URL 반환
    } catch (error: Throwable) {
        console.error("html2canvas 처리 중 오류 발생: $error")
        null // 오류 발생 시 null 반환
    }
}

// html2canvas를 외부 JS로 선언
external fun html2canvas(element: dynamic): dynamic