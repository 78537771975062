package com.damda.webapp.views.layouts

import androidx.compose.runtime.Composable
import androidx.compose.runtime.SideEffect
import kotlinx.browser.document
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLScriptElement

@Composable
fun NaverMap() {
    Div(attrs = {
        style {
            width(100.percent)
            height(100.percent)
            backgroundColor(Color.black)
        }
    }) {
        Div(
            attrs = {
                id("map")
                style {
                    width(100.percent)
                    height(100.percent)
                }
            }
        )
    }
}

@Composable
fun initNaverMap() {
    SideEffect {
        val scriptElement = document.createElement("script") as HTMLScriptElement
        scriptElement.src =
            "https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=qarpfa8oud"
        scriptElement.async = true
        scriptElement.defer = true

        scriptElement.onload = {
            val position = js("new naver.maps.LatLng(37.340824975507, 127.10708466493)") // 분당앤스퀘어 위치

            val mapOptions = js("{}")
            js("mapOptions.center = position")
            js("mapOptions.zoom = 17")

            // 여기서 상호작용 비활성화 옵션 추가
            js("mapOptions.draggable = false")
            js("mapOptions.pinchZoom = false")
            js("mapOptions.scrollWheel = false")
            js("mapOptions.keyboardShortcuts = false")
            js("mapOptions.disableDoubleTapZoom = true")
            js("mapOptions.disableDoubleClickZoom = true")

            val map = js("new naver.maps.Map(document.getElementById('map'), mapOptions)")

            val markerOptions = js("{}")
            js("markerOptions.position = position")
            js("markerOptions.map = map")
            val marker = js("new naver.maps.Marker(markerOptions)") // 마커 추가

            val infoWindowOptions = js("{}")
            js("infoWindowOptions.content = '<div style=\"padding:10px; background-color: #fff6dc; box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.1); border-radius: 0; border: none;\"><h4 style=\"margin: 0; padding: 0; color: #333; font-weight: bold;\">\uD83D\uDC90분당앤스퀘어</h4></div>'")

            val infoWindow = js("new naver.maps.InfoWindow(infoWindowOptions)")
            js("infoWindow.open(map, marker)")
        }
        document.head!!.appendChild(scriptElement)
    }
}