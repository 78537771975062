package com.damda.webapp.views.texts

import androidx.compose.runtime.Composable
import androidx.compose.runtime.staticCompositionLocalOf
import com.damda.webapp.views.layouts.JSColumn
import com.damda.webapp.views.layouts.JSSpacer
import com.damda.webapp.views.core.JSViewPadding
import com.damda.webapp.views.images.JSRoundedImage
import com.damda.webapp.views.layouts.ButtonSection
import com.damda.webapp.views.layouts.JSRow
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.minus
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Text

@Composable
fun JSHeaderText(
    title: String,
    subtitle: String,
    titleFontSize: CSSSizeValue<CSSUnit.px> = 18.px,
    subtitleFontSize: CSSSizeValue<CSSUnit.px> = 14.px,
    reduceFontSize: CSSSizeValue<CSSUnit.px> = 0.px
) {
    Div(
        {
            style {
                display(DisplayStyle.Flex)
                alignItems(AlignItems.Center) // 세로 가운데 정렬
            }
        }
    ) {
        JSColumn {
            StyledText(
                text = title,
                fontSize = titleFontSize,
                fontWeight = "bold",
                maxLines = 1,
                reduceFontSize = reduceFontSize
            )
            JSSpacer(height = 6.px)
            StyledText(
                text = subtitle,
                fontSize = subtitleFontSize,
                color = Color.gray,
                maxLines = 1,
                reduceFontSize = reduceFontSize
            )
        }
    }
}

@Composable
fun JSHeaderSection(
    title: String,
    subtitle: String,
    actionText: String,
    padding: JSViewPadding = JSViewPadding(0.px, 0.px, 0.px, 0.px),
    reduceFontSize: CSSSizeValue<CSSUnit.px> = 0.px,
    onActionClick: () -> Unit
) {
    Div({
        style {
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Row)
            justifyContent(JustifyContent.SpaceBetween)
            alignItems(AlignItems.Center)
            width(100.percent)

            paddingLeft(padding.left)
            paddingTop(padding.top)
            paddingRight(padding.right)
            paddingBottom(padding.bottom)
            boxSizing("border-box")
        }
    }) {
        JSColumn {
            StyledText(title, 20.px - reduceFontSize, fontWeight = "bold", color = rgb(51, 51, 51))
            JSSpacer(height = 8.px)
            StyledText(subtitle, 14.px - reduceFontSize, rgb(176, 176, 176))
        }
        JSSpacer(width = 8.px)
    }
}

data class TextInfoStyle(
    val text: String,
    val color: String,
    val weight: String
)
@Composable
fun TextSection(title: String, info: List<TextInfoStyle>) {
    Div(attrs = {
        style {
            textAlign("center")
        }
    }) {
        H2 {
            Text(title)
        }
        info.forEach { style ->
            Div({
                style { fontWeight(style.weight) }
            }) {
                if (style.text == "\n") {
                    Div(attrs = {
                        style {
                            height(28.px)
                        }
                    })
                } else {
                    Text(style.text)
                }
            }
        }
    }
}

@Composable
fun ContractContentSection(presentation: TextSectionPresentation, childOnCallClick: () -> Unit, childOnMessageClick: () -> Unit, isShowImage: Boolean = true) {
    Div({
        style {
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.SpaceBetween)  // JSRow와 ButtonSection을 양쪽 끝으로 배치
            alignItems(AlignItems.Center)               // 수직 중앙 정렬
            width(100.percent)
        }
    }) {
        JSRow(
            alignItem = AlignItems.Center,
            justifyContents = JustifyContent.FlexStart,
            modifier = {
                width(70.percent)
                height(72.px)
                paddingLeft(12.px)
            },
            content = {
                JSHeaderText(title = presentation.title, subtitle = presentation.subtitle)
            }
        )
        Div({
                style {
                    display(DisplayStyle.Flex)
                    justifyContent(JustifyContent.FlexEnd)  // 오른쪽 정렬
                    alignItems(AlignItems.Center)           // 수직 중앙 정렬
                    width(30.percent)  // 나머지 30%를 ButtonSection에 할당
                    paddingRight(12.px)
                }
            }
        ) {
            ButtonSection(childOnCallClick, childOnMessageClick)
        }
    }
}

data class TextSectionPresentation(
    val imageUrl: String,
    val title: String,
    val subtitle: String
)