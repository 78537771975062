package com.damda.webapp.views.screens

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.damda.invitation.ui.viewmodels.GalleryItem
import com.damda.webapp.views.layouts.AppBar
import com.damda.webapp.views.themes.strokeCardStyle
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.alignContent
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.bottom
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.minWidth
import org.jetbrains.compose.web.css.minus
import org.jetbrains.compose.web.css.overflow
import org.jetbrains.compose.web.css.overflowY
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.right
import org.jetbrains.compose.web.css.style
import org.jetbrains.compose.web.css.transform
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.get

@Composable
fun GalleryWebScreen(
    gallery: GalleryItem,
    allItems: List<String>,
    onBackArrowClick: () -> Unit
) {
    var currentIndex by remember { mutableStateOf(allItems.indexOf(gallery.url)) }
    Div(
        attrs = {
            style {
                width(100.percent)
                height(100.percent)
                overflowY("scroll")
                boxSizing("border-box")
            }
        }
    ) {
        AppBar("Gallery", onBackArrowClick = onBackArrowClick)
        Div(attrs = {
            style {
                width(100.percent)
                height(100.vh - 64.px)
            }
        }) {
            FullscreenImageSwiper(
                currentIndex = currentIndex,
                allItems = allItems,
                onChangeCurrentInt = {
                    currentIndex = it
                }
            )
            GalleryNavigator(
                currentIndex = currentIndex,
                allItems = allItems,
                onPreviousClick = {
                    currentIndex = if (currentIndex - 1 < 0) allItems.size - 1 else currentIndex - 1
                }, onNextClick = {
                    currentIndex = (currentIndex + 1) % allItems.size
                }
            )
        }
    }
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun FullscreenImageSwiper(
    currentIndex: Int,
    allItems: List<String>,
    onChangeCurrentInt: (Int) -> Unit
) {
    var startX by remember { mutableStateOf(0.0) }
    var currentX by remember { mutableStateOf(0.0) }
    var isSwiping by remember { mutableStateOf(false) }

    Div({
        style {
            display(DisplayStyle.Flex)
            overflow("hidden")
            width(100.vw)
            height(100.vh - 64.px)
            position(Position.Relative)
        }
        // Touch events
        onTouchStart { event ->
            val touch = event.changedTouches[0]
            startX = (touch?.clientX ?: 0.0).toDouble()
            isSwiping = true
        }
        onTouchMove { event ->
            if (isSwiping) {
                val touch = event.changedTouches[0]
                currentX = (touch?.clientX ?: 0.0).toDouble()
            }
        }
        onTouchEnd {
            isSwiping = false
            if (startX - currentX > 50) {
                onChangeCurrentInt((currentIndex + 1) % allItems.size)
            } else if (currentX - startX > 50) {
                onChangeCurrentInt(if (currentIndex - 1 < 0) allItems.size - 1 else currentIndex - 1)
            }
        }
    }) {
        allItems.forEachIndexed { index, url ->
            Div({
                style {
                    display(DisplayStyle.Flex)   // Flexbox로 설정
                    justifyContent(JustifyContent.Center)  // 수평 중앙 정렬
                    alignItems(AlignItems.Center)  // 수직 중앙 정렬
                    minWidth(100.percent)
                    property("transition", "transform 0.3s ease")
                    transform { translateX((-currentIndex * 100).percent) }
                }
            }) {
                Img(src = url, alt = "Image $index") {
                    style {
                        width(100.vw)
                        height(100.vh)
                        property("object-fit", "contain")
                    }
                }
            }
        }
    }
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun GalleryNavigator(
    currentIndex: Int,
    allItems: List<String>,
    onPreviousClick: () -> Unit,
    onNextClick: () -> Unit
) {
    Button(attrs = {
        style {
            position(Position.Absolute)
            property("top", "calc(50% + 64px)")
            left(10.px)

            strokeCardStyle()
            width(48.px)
            height(48.px)

            property("z-index", "1")
            cursor("pointer")
            borderRadius(50.percent)

            transform { translateY((-50).percent) }
        }
        onClick {
            onPreviousClick()
        }
    }) {
        Img(src = "ic_arrow_back.svg", alt = "Previous") {
            style {
                width(43.percent)
                height(43.percent)
            }
        }
    }

    Button(attrs = {
        style {
            position(Position.Absolute)
            property("top", "calc(50% + 64px)")
            right(10.px)

            strokeCardStyle()
            width(48.px)
            height(48.px)
            property("z-index", "1")
            cursor("pointer")
            borderRadius(50.percent)

            transform { translateY((-50).percent) }
        }
        onClick {
            onNextClick()
        }
    }) {
        Img(src = "ic_arrow_back.svg", alt = "Next") {
            style {
                width(43.percent)
                height(43.percent)
                transform { rotate(180.deg) }
            }
        }
    }

    Div({
        style {
            position(Position.Absolute)
            bottom(10.px)
            left(50.percent)
            transform {
                translateX((-50).percent)
            }
            width(72.px)
            height(32.px)
            backgroundColor(Color.white)
            border {
                color(Color.black)
                style(LineStyle.Solid)
                width(2.px)
            }
            borderRadius(12.px)
            property("box-shadow", "6px 6px 0px rgba(0, 0, 0, 1)")
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.Center)
            alignItems(AlignItems.Center)
        }
    }) {
        Text(((currentIndex + 1).toString() + " / " + allItems.size))
    }
}