package com.damda.webapp.views.screens

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.damda.invitation.core.ComposeWindowWidthSize
import com.damda.invitation.ui.viewmodels.GalleryItem
import com.damda.webapp.views.core.DialogMessage
import com.damda.webapp.views.layouts.ByeFooter
import com.damda.webapp.views.layouts.JSSpacer
import com.damda.webapp.views.layouts.VerticalLayout
import com.damda.webapp.views.layouts.initNaverMap
import com.damda.webapp.views.sections.main.AccountList
import com.damda.webapp.views.sections.main.ChatCardSection
import com.damda.webapp.views.sections.main.ContractList
import com.damda.webapp.views.sections.main.GalleryCardSection
import com.damda.webapp.views.sections.main.GreetingCardSection
import com.damda.webapp.views.sections.main.InformationSections
import com.damda.webapp.views.sections.main.IntroSection
import com.damda.webapp.views.sections.main.MapCardSection
import com.damda.webapp.views.sections.main.PromiseCardSection
import com.damda.webapp.views.themes.innerContainerStyle
import kotlinx.browser.document
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.animation
import org.jetbrains.compose.web.css.background
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.bottom
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.opacity
import org.jetbrains.compose.web.css.overflow
import org.jetbrains.compose.web.css.overflowY
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.right
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.transitions
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

val images = listOf(
    "thumbnail_gallery1.webp",
    "thumbnail_gallery2.webp",
    "thumbnail_gallery3.webp",
    "thumbnail_gallery4.webp",
    "thumbnail_gallery5.webp",
    "thumbnail_gallery6.webp",
    "thumbnail_gallery10.webp",
    "thumbnail_gallery11.webp",
    "thumbnail_gallery12.webp",
    "thumbnail_gallery7.webp",
    "thumbnail_gallery8.webp",
    "thumbnail_gallery9.webp",
)

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun MainWebScreen(
    windowWidthSize: ComposeWindowWidthSize,
    onClickGallery: (GalleryItem, List<String>) -> Unit
) {
    var isNotifyDialog = remember { mutableStateOf(false) }

    InvitationSurface {
        Div(attrs = {
            style {
                width(100.percent)
                height(100.percent)
                overflowY("auto")
            }
        }) {
            IntroSection()
            JSSpacer(height = 72.px)
            VerticalLayout(modifier = {
                width(100.percent)
                paddingLeft(24.px)
                paddingRight(24.px)
                paddingTop(20.px)
            }) {
                GreetingCardSection()
                JSSpacer(height = 192.px)
                PromiseCardSection()
                JSSpacer(height = 192.px)
                MapCardSection(callback = { initNaverMap() })
                JSSpacer(height = 192.px)
                ContractList()
                JSSpacer(height = 192.px)
                AccountList()
                JSSpacer(height = 40.px)
                InformationSections()
                JSSpacer(height = 40.px)
                GalleryCardSection { galleryItem, allItem ->
                    onClickGallery(galleryItem, allItem)
                }
                JSSpacer(height = 192.px)
            }
            ChatCardSection {
                isNotifyDialog.value = true
            }
            JSSpacer(height = 40.px)
            ByeFooter()

            if (isNotifyDialog.value) {
                DialogMessage ({
                    isNotifyDialog.value = false
                }, {
                    Text("내용과 닉네임을 필수적으로 입력해주세요!")
                })
            }
        }
    }
}

@Composable
fun InvitationSurface(content: @Composable () -> Unit) {
    Div(attrs = {
        style {
            height(100.vh)
            innerContainerStyle()
        }
    }) {
        content()
    }
}

object WeddingAnnouncementStyle : StyleSheet() {
    val container by style {
        width(100.percent)
        height(100.percent)
        backgroundColor(Color.white)
        position(Position.Relative)
    }

    val imageContainer by style {
        width(100.percent)
        height(66.vh)
        overflow("hidden")
        position(Position.Relative)
    }

    val dimOverlay by style {
        position(Position.Absolute)
        bottom(0.px)
        left(0.px)
        right(0.px)
        height(100.px)
        background("linear-gradient(to bottom, rgba(250,250,250,0), rgba(250,250,250,1))")
    }

    val textContainer by style {
        padding(40.px)
        textAlign("center")
        position(Position.Relative)
        property("z-index", "1")
    }

    val title by style {
        paddingLeft(24.px)
        paddingRight(24.px)
        display(DisplayStyle.InlineBlock)
        position(Position.Relative)
        fontSize(32.px)
        color(Color("#101010"))
        marginBottom(12.px)
        property("text-shadow", "2px 2px 4px rgba(0, 0, 0, 0.1)")
    }

    val names by style {
        fontSize(24.px)
        color(Color("#555"))
        marginBottom(20.px)
    }

    val details by style {
        display(DisplayStyle.InlineBlock)
        border(1.px, LineStyle.Solid, Color("#ddd"))
        padding(20.px)
        backgroundColor(Color("#f9f9f9"))
        property("box-shadow", "0 0 10px rgba(0, 0, 0, 0.05)")
    }

    val highlight by style {
        color(Color("#303030"))
        fontWeight("bold")
    }
    val loveText by style {
        color(Color("#FFB6C1"))
        fontWeight("bold")
    }
}