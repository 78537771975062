package com.damda.webapp.views.sections.main

import androidx.compose.runtime.Composable
import com.damda.invitation.themes.Colors
import com.damda.webapp.views.core.openMaps
import com.damda.webapp.views.layouts.ActionButton
import com.damda.webapp.views.layouts.Card
import com.damda.webapp.views.layouts.CardBody
import com.damda.webapp.views.layouts.CardHeader
import com.damda.webapp.views.layouts.JSSpacer
import com.damda.webapp.views.layouts.NaverMap
import com.damda.webapp.views.texts.TextInfoStyle
import com.damda.webapp.views.texts.TextSection
import com.damda.webapp.views.themes.fullContainerStyle
import com.damda.webapp.views.themes.strokeBoldCardStyle
import com.damda.webapp.views.themes.toCssColor
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Text

@Composable
fun MapCardSection(callback: @Composable ()->Unit) {
    Div({
        style {
            width(100.percent)
            height(90.vh)
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.Center)
            alignItems(AlignItems.Center)
        }
    }) {
        Card(modifier = {
            textAlign("center")
            width(90.vw)
        }, className = "fade-section") {
            // image 영역
            H2 {
                Text("오시는 길 \uD83D\uDDFA\uFE0F")
            }
            JSSpacer(height = 16.px)
            CardHeader(
                modifier = {
                    height(480.px)
                },
                content = {
                    NaverMap()
                })

            CardBody(
                modifier = {
                    width(auto)
                    height(auto)
                },
                content = {
                    JSSpacer(height = 16.px)
                    TextSection(
                        title = "",
                        info = listOf(
                            TextInfoStyle("경기 성남시 분당구 탄천상로151번길 20", "#000000", "300"),
                            TextInfoStyle("분당앤스퀘어 4층, 아모르홀 (오리역 6번출구 앞)", "#000000", "500")
                        )
                    )
                    JSSpacer(height = 16.px)
                    ActionButton("길찾기", Colors.pinkColor.toCssColor()) {
                        openMaps(37.340824975507, 127.10708466493, "분당앤스퀘어 예식장")
                    }
                }
            )
        }
        callback()
    }
}