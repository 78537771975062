package com.damda.webapp.views.chats

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.damda.invitation.presentation.ChatMessage
import com.damda.invitation.presentation.DateMessage
import com.damda.invitation.presentation.LocalStickerMessage
import com.damda.invitation.presentation.QuizMessage
import com.damda.invitation.themes.Colors
import com.damda.webapp.views.layouts.Card
import com.damda.webapp.views.layouts.JSSpacer
import com.damda.webapp.views.layouts.VerticalLayout
import com.damda.webapp.views.sections.main.formatTime
import com.damda.webapp.views.texts.TextSectionPresentation
import com.damda.webapp.views.themes.toCssColor
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.AlignSelf
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.alignSelf
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flex
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.maxWidth
import org.jetbrains.compose.web.css.overflowY
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rowGap
import org.jetbrains.compose.web.css.top
import org.jetbrains.compose.web.css.whiteSpace
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date

@Composable
fun MessageButton(quizMessage: QuizMessage, onClick: () -> Unit, onSendQuizMessage: (QuizMessage) -> Unit, notifyDialog: () -> Unit,) {
    var quizStep by remember { mutableStateOf(quizMessage.step) }
    Div({
        style {
            backgroundColor(Color.white)
            padding(12.px)
            borderRadius(8.px)
            property("box-shadow", "4px 4px 4px 0px rgba(0, 0, 0, 0.1)")
        }
    }) {
        if (quizStep == "0") {
            Div({ classes(AppStylesheet.quizContainer) }) {
                H3({ classes(AppStylesheet.title) }) { Text("우정 테스트 시작하기") }
                Button({
                    style {
                        width(100.percent)
                        margin(12.px, 0.px)
                        padding(10.px)
                        backgroundColor(Colors.pinkColor.toCssColor())
                        border(0.px)
                        borderRadius(4.px)
                        cursor("pointer")
                    }
                    onClick {
                        quizStep = "1"
                        onClick()
                    }
                }) {
                    Text(quizMessage.content)
                }
            }
        } else if (quizStep == "1") {
            QuizGame(onSendQuizMessage, notifyDialog, onClick)
            onClick()
        }
    }
}

@Composable
fun ReceivedImageMessage(quizMessage: QuizMessage) {
    Div({ classes(AppStylesheet.quizContainer)
        style {
            backgroundColor(Color.white)
            borderRadius(16.px)
            padding(12.px)
        }
    }) {
        val score = quizMessage.score.toInt()
        Div(
            {
                style {
                    maxWidth(200.px)
                    display(DisplayStyle.Flex)
                    justifyContent(JustifyContent.Center)
                    alignItems(AlignItems.Center)
                }
            }
        ) {
            Img(
                src = when {
                    score == 5 -> "score5.jpg"
                    score >= 3 -> "score3.jpg"
                    score >= 1 -> "score1.jpg"
                    else -> "score0.jpg"
                },
                attrs = {
                    style {
                        maxWidth(200.px)
                    }
                }
            )
        }
        JSSpacer(height = 12.px)
        Text(quizMessage.content)
    }
}

@Composable
fun DateMessageUI(dateMessage: DateMessage) {
    Div({
        style {
            width(100.percent)
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.Center)
            alignItems(AlignItems.Center)
            margin(8.px)
        }
    }) {
        Card(modifier = {
            backgroundColor(Color("#10333333"))
            color(Color("#6f6f6f"))
            fontSize(12.px)
            borderRadius(12.px)
            paddingLeft(12.px)
            paddingRight(12.px)
            paddingBottom(8.px)
            paddingTop(8.px)
        }) {
            Text(dateMessage.printDate)
        }
    }
}
@Composable
fun SentMessage(chatMessage: ChatMessage) {
    Div({
        style {
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.FlexEnd)
            marginBottom(8.px)
            width(100.percent) // 이 부분을 추가하여 SentMessage가 오른쪽 끝에 붙도록 함
        }
    }) {
        MessageTime(time = Date().getTime().toString())
        if (chatMessage is QuizMessage) {
            ReceivedImageMessage(chatMessage)
        } else {
            MessageBubble(content = chatMessage.content, backgroundColor = Color("#ffeb3b"), marginEnd = 32.px)
        }
    }
}

@Composable
fun ReceivedMessage(message: ChatMessage, onClick: () -> Unit, onSendQuizMessage: (QuizMessage) -> Unit, notifyDialog: () -> Unit) {
    val profileUrl = if (message.profileUrl == "empty") {
        "pic8.jpeg"
    } else {
        message.profileUrl
    }
    val profileName = if (message.profileName.length > 10) {
        message.profileName.substring(0, 10) + "..."
    } else {
        message.profileName.substring(0, message.profileName.length)
    }
    ChatProfile(TextSectionPresentation(profileUrl, profileName, " "))
    Div({
        style {
            width(auto)
            maxWidth(84.percent)
            display(DisplayStyle.Flex)
            alignItems(AlignItems.Center)
            position(Position.Relative)
            top((-9).px)
            marginLeft(60.px)
            rowGap(12.px)
            whiteSpace("normal")
            property("white-space", "normal")
            property("word-break", "break-word")
        }
    }) {
        if (message is QuizMessage) {
            if (message.step == "2") { //step2인 경우는 결과
                console.log("in quiz message")
                ReceivedImageMessage(message)
            } else {
                MessageButton(message, onClick, onSendQuizMessage, notifyDialog)
            }
        } else if (message is LocalStickerMessage) {
            // ReceivedImageMessage(message.imageUrl)
        } else {
            if (message.profileId == "12345") {
                MessageBubble(content = message.content, backgroundColor = Colors.pinkColor.toCssColor(), marginEnd = 0.px)
            } else {
                MessageBubble(content = message.content, backgroundColor = Color.white, marginEnd = 0.px)
            }
        }

        MessageTime(time = message.createdTime)
    }
}

@Composable
fun MessageBubble(content: String, backgroundColor: CSSColorValue, marginEnd: CSSSizeValue<CSSUnit.px>) {
    Div({
        style {
            backgroundColor(backgroundColor)
            padding(12.px)
            borderRadius(8.px)
            property("box-shadow", "4px 4px 4px 0px rgba(0, 0, 0, 0.1)")
            marginRight(marginEnd)
        }
    }) {
        Text(content)
    }
}

@Composable
fun MessageButton(content: String, backgroundColor: CSSColorValue, marginEnd: CSSSizeValue<CSSUnit.px>) {
    Div({
        style {
            backgroundColor(backgroundColor)
            padding(12.px)
            borderRadius(8.px)
            property("box-shadow", "4px 4px 4px 0px rgba(0, 0, 0, 0.1)")
            marginRight(marginEnd)
        }
    }) {
        Button { Text(content) }
    }
}

@Composable
fun MessageTime(time: String) {
    Div({
        style {
            width(auto)
            fontSize(11.px)
            color(Color.gray)
            alignSelf(AlignSelf.FlexEnd)
            paddingLeft(4.px)
            paddingRight(4.px)
            paddingBottom(4.px)
            whiteSpace("nowrap")
        }
    }) {
        Text(formatTime(time))
    }
}
