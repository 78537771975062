package com.damda.webapp.views.sections.main

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.damda.webapp.views.layouts.Card
import com.damda.webapp.views.layouts.CardBody
import com.damda.webapp.views.layouts.JSSpacer
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flex
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.maxWidth
import org.jetbrains.compose.web.css.minHeight
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun InformationSections() {
    var activeTab by remember { mutableStateOf(0) }

    // 탭의 제목과 각 탭의 콘텐츠
    val tabs = listOf("주차안내", "식사안내")
    val tabContents = listOf(
        "분당앤스퀘어 건물 뒷편 홈플러스, CGV, 애플프라자 주차장 입구 이용\n" + "지하 3층, 지하 4층 주차장 이용\n" + "웨딩홀 전용 엘리베이터 7,8호기를 이용하시면 편리합니다.\n" + "주차 이용 가능 시간은 2시간입니다.",
        "식사는 오전 11시 30분에 진행될 예정입니다.",
        "식사는 본관 한 층 위인 5층에서 진행됩니다."
    )

    Card(modifier = {
        width(100.percent)
        height(90.vh)
        display(DisplayStyle.Flex)
        justifyContent(JustifyContent.Center)
        alignItems(AlignItems.Center)
        textAlign("center")
    }, className = "fade-section") {
        JSSpacer(height = 16.px)
        CardBody(
            modifier = {
                width(85.percent)
                height(auto)
                boxSizing("border-box")  // 패딩을 요소 크기에 포함
            },
            content = {
                H2 {
                    Text("식사 및 주차 안내 🚗")
                }
                Div(attrs = {
                    style {
                        property("overflow", "hidden")
                        property("opacity", "1")
                        property("transform", "translate(0px, 0px)")
                        border(1.px, LineStyle.Solid, Color.lightgray)
                        borderRadius(12.px)
                        maxWidth(100.percent)
                        width(100.percent)
                        margin(0.px)
                    }
                }) {
                    // 탭 제목 영역
                    Div(attrs = {
                        style {
                            display(DisplayStyle.Flex)
                            backgroundColor(Color("#f2f2f2"))
                        }
                    }) {
                        tabs.forEachIndexed { index, title ->
                            Div(attrs = {
                                style {
                                    padding(16.px)
                                    textAlign("center")
                                    cursor("pointer")
                                    flex(1)
                                    if (index == activeTab) {
                                        backgroundColor(Color("#e9ecef"))
                                        fontWeight("bold")
                                    }
                                }
                                onClick { activeTab = index }
                            }) {
                                Span { Text(title) }
                            }
                        }
                    }

                    // 탭 내용 영역
                    Div(attrs = {
                        style {
                            padding(24.px)
                            backgroundColor(Color.white)
                            borderRadius(0.px, 0.px, 12.px, 12.px)
                            minHeight(200.px)
                            textAlign("left")
                        }
                    }) {
                        tabContents[activeTab].split("\n").forEach {
                            P { Text("- $it") }
                        }
                    }
                }
            }
        )
    }
}