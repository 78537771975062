package com.damda.webapp.views.sections.main

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.damda.invitation.themes.Colors
import com.damda.webapp.views.layouts.JSColumn
import com.damda.webapp.views.layouts.JSRow
import com.damda.webapp.views.layouts.JSSpacer
import com.damda.webapp.views.layouts.VerticalLayout
import com.damda.webapp.views.texts.JSHeaderText
import com.damda.webapp.views.texts.StyledText
import com.damda.webapp.views.themes.toCssColor
import kotlinx.browser.window
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.fontFamily
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.transitions
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

data class Account(val name: String, val bankName: String, val number: String)
data class AccountGroup(val title: String, val accounts: List<Account>)

@Composable
fun AccountList() {
    val accountGroups = remember {
        listOf(
            AccountGroup("신랑측 계좌번호", listOf(
                Account("문병학", "카카오뱅크","3333248526852"),
                Account("성승희", "국민","04000204081382")
            )),
            AccountGroup("신부측 계좌번호", listOf(
                Account("이홍주", "카카오뱅크","3333083663549"),
                Account("최순덕", "국민", "69890101000861")
            ))
        )
    }

    Div(
        attrs = {
            style {
                height(100.vh)
                display(DisplayStyle.Flex)
                justifyContent(JustifyContent.Center)
                alignItems(AlignItems.Center)
            }
        }
    ) {
        Div(
            attrs = {
                style {
                    width(100.percent)
                    textAlign("center")
                }
            }
        ) {
            H2 {
                Text("마음 전하실 곳 \uD83D\uDC8C")
            }
            JSSpacer(12.px)
            VerticalLayout {
                accountGroups.forEach { group ->
                    Div({
                        style {
                            property("overflow", "hidden")
                            property("opacity", "1")
                            property("transform", "translate(0px, 0px)")
                            border(1.px, LineStyle.Solid, Color.lightgray)
                            borderRadius(12.px)
                            backgroundColor(Color("#f2f2f2"))
                            fontFamily("Arial", "sans-serif")
                            paddingTop(12.px)
                            marginBottom(20.px)
                        }
                    }) {
                        AccountGroupItem(group)
                    }
                }
            }
        }
    }
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun AccountGroupItem(group: AccountGroup) {
    var isExpanded by remember { mutableStateOf(false) }

    Div({
        style {
            marginBottom(41.px)
        }
    }) {
        Div({
            onClick { isExpanded = !isExpanded }
            style {
                width(100.percent)
                height(100.percent)
                cursor("pointer")
                display(DisplayStyle.Flex)
                alignItems(AlignItems.Center)
                justifyContent(JustifyContent.Center)
                marginBottom(16.px)
            }
        }) {
            H4({
                style {
                    color(Color("#333"))
                    margin(0.px)
                }
            }) {
                Text(group.title)
            }
            Span({
                style {
                    marginLeft(8.px)
                    fontSize(18.px)
                    transitions {
                        "transform" { duration = 300.ms }
                    }
                    if (isExpanded) {
                        property("transform", "rotate(180deg)")
                    }
                }
            }) {
                Text("▼")
            }
        }
        if (isExpanded) {
            group.accounts.forEach { account ->
                AccountItem(account)
            }
        }
    }
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun AccountItem(account: Account) {
    Div({
        style {
            backgroundColor(Color.white)
            marginBottom(20.px)
            padding(16.px)
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.SpaceBetween)
        }
    }) {
        Div({
            style {
                display(DisplayStyle.Flex)        // Flexbox 설정
                flexDirection(FlexDirection.Column) // 세로로 정렬
                justifyContent(JustifyContent.FlexStart) // 위쪽부터 정렬
                alignItems(AlignItems.FlexStart)    // 왼쪽 정렬
            }
        }) {
            Div({
                style {
                    display(DisplayStyle.Flex)        // Flexbox 설정
                    flexDirection(FlexDirection.Row) // 세로로 정렬
                    justifyContent(JustifyContent.FlexStart) // 위쪽부터 정렬
                    alignItems(AlignItems.FlexStart)    // 왼쪽 정렬
                    fontSize(13.px)
                }
            }) {
                Text(account.bankName)
                Text("  ")
                Text(account.number)
            }
            Div({
                style {
                    height(16.px)
                }
            }) {

            }
            Div {
                Text(account.name)
            }
        }

        Button({
            onClick { copyToClipboard(account.number) }
            style {
                backgroundColor(Color.white)
                border(0.px)
                padding(8.px, 12.px)
                borderRadius(8.px)
                cursor("pointer")
                transitions {
                    "background-color" { duration = 300.ms }
                }
                color(Color("#4b4b4b"))
                height(32.px)
                border {
                    style = LineStyle.Solid
                    width = 0.75.px
                    color = Color("#4b4b4b")
                }
            }
        }) {
            JSRow {
                Img("ic_copy.png") {
                    style {
                        width(16.px)
                        height(16.px)
                        marginRight(8.px)
                    }
                }
                Div({
                    style {
                        fontSize(14.px)
                    }
                }) {
                    Text("복사")
                }
            }
        }
    }
}

fun copyToClipboard(text: String) {
    js("navigator.clipboard.writeText(text)").then {
        window.alert("계좌번호가 복사되었습니다!")
    }.catch { err ->
        console.error("복사 중 오류가 발생했습니다:", err)
    }
}