package com.damda.invitation.presentation

import com.damda.invitation.domain.ChatMessageDomain
import io.ktor.http.HttpHeaders.Date
import io.ktor.util.date.GMTDate
import io.ktor.util.date.WeekDay

open class ChatMessage(
    open val content: String,
    open val profileId: String,
    open val profileUrl: String,
    open val profileName: String,
    open val createdTime: String
)

data class LocalStickerMessage(
    val imageUrl: String,
    override val content: String,
    override val profileId: String,
    override val profileUrl: String,
    override val profileName: String,
    override val createdTime: String
): ChatMessage(
    content, profileId, profileUrl, profileName, createdTime
)

data class QuizMessage(
    var step: String = "-1",
    var score: String = "0",
    var savedQuizImage: String,
    override var content: String,
    override val profileId: String,
    override val profileUrl: String,
    override var profileName: String,
    override val createdTime: String
): ChatMessage(
    content, profileId, profileUrl, profileName, createdTime
)

data class DateMessage(
    val printDate:String,
    override val content: String,
    override val profileId: String,
    override val profileUrl: String,
    override val profileName: String,
    override val createdTime: String
): ChatMessage(
    content, profileId, profileUrl, profileName, createdTime
)

fun QuizMessage.toChatMessageDomain(): ChatMessageDomain {
    return ChatMessageDomain(content, profileId, profileUrl, profileName, createdTime, step, score)
}
fun ChatMessage.toChatMessageDomain(): ChatMessageDomain {
    return ChatMessageDomain(content, profileId, profileUrl, profileName, createdTime)
}

fun getGreetingMessages(): List<ChatMessage> {
    return listOf(
        ChatMessage("안녕하세요. 저희를 축하해주기 위해서 방명록을 작성해주시겠어요~?\uD83D\uDD25\uD83D\uDD25\uD83D\uDD25","12345","mbh.jpeg", "문병학", "20240101"),
        //LocalStickerMessage("sticker1.png", "","12345","mbh.jpeg", "문병학", "20240101"),
        ChatMessage("간단한 퀴즈도 준비해봤어요! 시작하려면 풀어보기를 눌러주세요!","12345","mbh.jpeg", "문병학", "20240101"),
        QuizMessage("0", "0","","풀어보기","12345","mbh.jpeg", "문병학", "20240101")
    )
}

fun addDateMessages(chatMessages: List<ChatMessage>): List<ChatMessage> {
    if (chatMessages.isEmpty()) return chatMessages

    val result = mutableListOf<ChatMessage>()
    var lastDate: String? = null

    for (message in chatMessages) {
        val date = GMTDate(message.createdTime.toLong())

        val compared = ""+ date.year + date.month + date.dayOfMonth
        if (lastDate != compared) {
            // 날짜가 바뀔 때마다 DateMessage 추가
            val weekday = when(date.dayOfWeek) {
                WeekDay.MONDAY -> "월요일"
                WeekDay.TUESDAY -> "화요일"
                WeekDay.WEDNESDAY -> "수요일"
                WeekDay.THURSDAY -> "목요일"
                WeekDay.FRIDAY -> "금요일"
                WeekDay.SATURDAY -> "토요일"
                WeekDay.SUNDAY -> "일요일"
                else -> ""
            }
            result += DateMessage(
                "${date.year}년 ${date.month.ordinal}월 ${date.dayOfMonth}일 $weekday",
                message.content,
                message.profileId,
                message.profileUrl,
                message.profileName,
                message.createdTime
            )
            lastDate = compared
        }

        result += message
    }

    return result
}