package com.damda.invitation.domain

import com.damda.invitation.data.FirebaseChatMessage
import com.damda.invitation.data.FirebaseField
import com.damda.invitation.presentation.ChatMessage
import com.damda.invitation.presentation.QuizMessage

data class ChatMessageDomain(
    val content: String,
    val profileId: String,
    val profileUrl: String,
    val profileName: String,
    val createdTime: String,
    var step: String = "-1",
    var score: String = "-1",
    var savedQuizImage: String = ""
)

fun ChatMessageDomain.toFirebaseChatMessage(): FirebaseChatMessage {
    return FirebaseChatMessage(
        fields = mapOf(
            "content" to FirebaseField(content),
            "profileId" to FirebaseField(profileId),
            "profileUrl" to FirebaseField(profileUrl),
            "profileName" to FirebaseField(profileName),
            "createdTime" to FirebaseField(createdTime),
            "step" to FirebaseField(step),
            "score" to FirebaseField(score)
        )
    )
}

fun ChatMessageDomain.toChatMessage(): ChatMessage {
    return if (this.step == "2") {
        QuizMessage(step, score, savedQuizImage, content, profileId, profileUrl, profileName, createdTime)
    } else {
        ChatMessage(content, profileId, profileUrl, profileName, createdTime)
    }
}