package com.damda.invitation.ui.viewmodels

import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateOf
import com.damda.invitation.core.Event

class ScreenState {
    private val screenList = mutableListOf<WeddingScreen>()
    private val _onScreenEvent = mutableStateOf<Event<WeddingScreen>>(Event(WeddingScreen.Home))
    val onScreenEvent: State<Event<WeddingScreen>> get() = _onScreenEvent

    init {
        screenList.add(WeddingScreen.Home)  // 초기 화면 추가
    }

    fun changeScreen(weddingScreen: WeddingScreen) {
        // 스크린 리스트에서 해당 화면이 있는지 확인
        val existingScreen = screenList.find { it == weddingScreen }

        if (existingScreen != null) {
            _onScreenEvent.value = Event(existingScreen)
        } else {
            // 새 화면을 리스트에 추가하고 전환
            screenList.add(weddingScreen)
            _onScreenEvent.value = Event(weddingScreen)
        }
    }
}

sealed class WeddingScreen {
    data object Home: WeddingScreen();
    data class Gallery(val item: GalleryItem, val allItems: List<String>): WeddingScreen();
}

data class GalleryItem (
    val url: String
)