package com.damda.invitation.themes

import androidx.compose.ui.graphics.Color

object Colors {
    // Primary colors
    val primaryColor = Color(0xFFDDDFD1) // Light Purple
    val primaryVariantColor = Color(0xFF1A1821) // Dark Gray
    val pinkColor = Color(0xFFFFB6C1)
    val secondaryColor = Color(0xFFeae3f1)
    val secondaryVariantColor = Color(0xFF603544) // Dark Brown

    val pointColor = Color(0xFF61d8af)
    // Background and surface colors
    val backgroundColor = Color(0xFFFAFAFA) // Near White
    val lightGrayColor = Color(0xFFFAF3EE)
    val surfaceColor = Color(0xFF7A6F7E) // Medium Gray
    val errorColor = Color(0xFF7C535A) // Dark Pink

    // On colors
    val onPrimaryColor = Color(0xFFDBD5DF) // Light Gray
    val onSecondaryColor = Color(0xFF1e192a) // Light Purple
    val onTertiaryColor = Color(0xFFEDB6C6) // Light Pink
    val onErrorColor = Color(0xFF300C16) // Dark Red
    val onPrimaryContainer = Color(0xFFC6BFCC) // Light Gray
    val onSecondaryContainer = Color(0xFF342B37) // Dark Purple
    val onTertiaryContainer = Color(0xFFF9DDE0) // Light Pink
    val onErrorContainer = Color(0xFF040404) // Black
    val surfaceVariant = Color(0xFFCCBEF8) // Light Purple
    val onSurfaceVariant = Color(0xFF46454f) // Medium Gray

    // Additional colors if needed
    val primaryContainer = Color(0xFF47405C) // Dark Gray
    val secondaryContainer = Color(0xFF615879) // Medium Gray
    val tertiaryContainer = Color(0xFF1A0D41) // Dark Purple
    val outlineVariant = Color(0xFFc6c4cf)
}