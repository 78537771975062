package com.damda.webapp.views.sections.main

import androidx.compose.runtime.Composable
import com.damda.invitation.themes.Colors
import com.damda.webapp.views.chats.AppStylesheet
import com.damda.webapp.views.layouts.Card
import com.damda.webapp.views.layouts.CardBody
import com.damda.webapp.views.layouts.JSSpacer
import com.damda.webapp.views.texts.TextInfoStyle
import com.damda.webapp.views.texts.TextSection
import com.damda.webapp.views.themes.fullContainerStyle
import com.damda.webapp.views.themes.strokeBoldCardStyle
import com.damda.webapp.views.themes.strokeCardStyle
import com.damda.webapp.views.themes.toCssColor
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.backgroundImage
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

@Composable
fun PromiseCardSection() {
    Card(modifier = {
        height(90.vh)
        display(DisplayStyle.Flex)
        justifyContent(JustifyContent.Center)
        alignItems(AlignItems.Center)
    }, className = "fade-section") {
        CardBody(
            modifier = {
                width(100.vw)
                height(auto)
                property("background-image", "url('wedding_sticker_background.webp')")
                paddingTop(24.px)
                paddingBottom(24.px)
            },
            content = {
                Div(attrs = {
                    style {
                        textAlign("center")
                    }
                }) {
                    H2 {
                        Text("서약 \uD83D\uDC8C")
                    }
                    Div(
                        {
                            style {
                                width(100.percent)
                                display(DisplayStyle.Flex)
                                justifyContent(JustifyContent.Center)
                                alignItems(AlignItems.Center)
                            }
                        }
                    ) {
                        Img(src = "wedding_sticker_character.webp"){
                            style {
                                width(256.px)
                            }
                        }
                    }
                    JSSpacer(height = 24.px)
                    listOf(
                        TextInfoStyle("서로가 마주보며 다져온 사랑을", "#000000", "300"),
                        TextInfoStyle("이제 함께 한 곳을 바라보며 걸어갈 수 있는", "#000000", "300"),
                        TextInfoStyle("큰 사랑으로 키우고자 합니다.", "#000000", "300"),
                        TextInfoStyle("\n", "#000000", "300"),
                        TextInfoStyle("7년간의 연애 프로젝트를 완성하는 자리에", "#000000", "300"),
                        TextInfoStyle("귀한 시간 내주셔서 리뷰어로", "#000000", "300"),
                        TextInfoStyle("함께 봐주시면 감사하겠습니다.", "#000000", "300"),
                        TextInfoStyle("\n", "#000000", "300"),
                        TextInfoStyle("여러분의 따뜻한 축복과 응원을 부탁드립니다.", "#000000", "300"),
                    ).forEach { style ->
                        Div({
                            style { fontWeight(style.weight) }
                        }) {
                            if (style.text == "\n") {
                                Div(attrs = {
                                    style {
                                        height(28.px)
                                    }
                                })
                            } else {
                                Text(style.text)
                            }
                        }
                    }
                }

            }
        )
    }
}