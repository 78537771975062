package com.damda.invitation.firebases

data class FirebaseConfig(
    val apiKey: String,
    val projectId: String,
    val messagingSenderId: String,
    val appId: String
)

val firebaseConfig = FirebaseConfig(
    apiKey = "AIzaSyDJ1rv3bscwDGbeIgfLEIbPDThs_n3Iy4o",
    projectId = "invito-5e9a2",
    messagingSenderId = "360338061295",
    appId = "1:360338061295:web:763b8f74696162ce76e81a"
)