package com.damda.webapp.views.layouts

import androidx.compose.runtime.Composable
import com.damda.invitation.themes.Colors
import com.damda.webapp.views.themes.strokeCardStyle
import com.damda.webapp.views.themes.toCssColor
import kotlinx.browser.window
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.bottom
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.minus
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.right
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun JSFloatingActionButton(onClick: () -> Unit) {
    Div({
        onClick { onClick() }
        style {
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.Center)
            alignItems(AlignItems.Center)
            width(32.px)
            height(32.px)
            backgroundColor(rgb(255, 167, 38))
            color(Color.white)
            borderRadius(50.percent)
            property("box-shadow", "0px 4px 4px rgba(0, 0, 0, 0.25)")
            cursor("pointer")
            position(Position.Absolute)
            bottom(18.px)
            right(18.px)
        }
    }) {
        Text("+")
    }
}

@Composable
fun WidthFullSizeButton(
    title: String,
    onClick: () -> Unit,
    reduceFontSize: CSSSizeValue<CSSUnit.px> = 0.px
) {
    Button(
        attrs = {
            onClick { onClick() }
            style {
                width(100.percent)
                height(70.px)
                backgroundColor(Color("#EC994B"))
                border(0.px)
                color(Color.white)
                fontSize(20.px - reduceFontSize)
                fontWeight("bold")
                padding(12.px)
                borderRadius(30.px)
            }
        }
    ) {
        Text(title)
    }
}

@Composable
fun ActionButton(text: String, backgroundColor: CSSColorValue, onClick: (() -> Unit)? = null) {
    Card(modifier = {
        strokeCardStyle()
        marginTop(12.px)
        width(100.percent)
        height(47.px)
        backgroundColor(backgroundColor)
        alignItems(AlignItems.Center)
        display(DisplayStyle.Flex)
        justifyContent(JustifyContent.Center)
    }, clickListener = {
            onClick?.invoke()
    }) {
        Text(text)
    }
}

@Composable
fun RoundingActionButton(imageUrl: String, backgroundColor: CSSColorValue, onClick: (() -> Unit)? = null) {
    Card(modifier = {
        strokeCardStyle()
        width(100.percent)
        height(100.percent)
        backgroundColor(backgroundColor)
        alignItems(AlignItems.Center)
        display(DisplayStyle.Flex)
        justifyContent(JustifyContent.Center)
        borderRadius(50.percent)
    }, clickListener = {
        onClick?.invoke()
    }, borderRadius = 50.percent) {
        Img(imageUrl) {
            style {
                width(50.percent)
                height(50.percent)
                property("object-fit", "cover")  // 이미지를 박스 크기에 맞춤
                property("object-position", "center")  // 이미지의 중앙 부분이 보이게 설정
                display(DisplayStyle.Block)  // 불필요한 여백 방지
            }
        }
    }
}

@Composable
fun ButtonSection(
    onCallClick:() -> Unit,
    onMessageClick:() -> Unit
) {
    JSRow(modifier = {
        height(82.px)
        justifyContent(JustifyContent.FlexEnd) // 맨 밑에서부터 정렬
        alignItems(AlignItems.Center)           // 수직 중앙 정렬
        display(DisplayStyle.Flex)
    }) {
        Div(
            {
                style {
                    width(48.px)
                    height(48.px)
                }
            }
        ) {
            RoundingActionButton("ic_call.png", Color.white, onCallClick)
        }
        JSSpacer(width = 2.vh)
        Div(
            {
                style {
                    width(48.px)
                    height(48.px)
                }
            }
        ) {
            RoundingActionButton("ic_sms.png", Color.white, onMessageClick)
        }
    }
}

@Composable
fun ByeFooter() {
    Div({
        style {
            width(100.percent)
            backgroundColor(Colors.lightGrayColor.toCssColor())
            padding(16.px)
            textAlign("center")
            boxSizing("border-box")
        }
    }) {
        P({ style { marginBottom(8.px) } }) {
            Text("행복하게 잘 살도록 하겠습니다.")
        }
        P({ style { marginBottom(8.px) } }) {
            Text("부디 귀한 발걸음 하셔서 축복해 주시길 바랍니다.")
        }
        Button({
            onClick {
                copyToClipboard()
            }
            style {
                marginTop(16.px)
                marginBottom(32.px)
                padding(8.px, 16.px)
                backgroundColor(Color("#FEE500"))
                border(0.px)
                borderRadius(4.px)
                cursor("pointer")
            }
        }) {
            Text("링크 복사하기")
        }
    }
}

fun copyToClipboard() {
    val url = window.location.href
    js("navigator.clipboard.writeText(url)").then {
        window.alert("링크가 복사되었습니다!")
    }.catch { err ->
        console.error("복사 중 오류가 발생했습니다:", err)
    }
}