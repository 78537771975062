package com.damda.webapp.views.sections.main

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.damda.invitation.domain.toChatMessage
import com.damda.invitation.firebases.FirebaseClient
import com.damda.invitation.firebases.firebaseConfig
import com.damda.invitation.presentation.ChatMessage
import com.damda.invitation.presentation.DateMessage
import com.damda.invitation.presentation.QuizMessage
import com.damda.invitation.presentation.addDateMessages
import com.damda.invitation.presentation.getGreetingMessages
import com.damda.invitation.presentation.toChatMessageDomain
import com.damda.webapp.views.chats.DateMessageUI
import com.damda.webapp.views.chats.ReceivedMessage
import com.damda.webapp.views.chats.SentMessage
import com.damda.webapp.views.core.UserIdManager
import com.damda.webapp.views.layouts.ChatStatusBar
import com.damda.webapp.views.layouts.ChatTopBar
import com.damda.webapp.views.layouts.JSSpacer
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.attributes.readOnly
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.backgroundImage
import org.jetbrains.compose.web.css.backgroundPosition
import org.jetbrains.compose.web.css.backgroundRepeat
import org.jetbrains.compose.web.css.backgroundSize
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flex
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.minus
import org.jetbrains.compose.web.css.overflowY
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLElement
import kotlin.js.Date

@Composable
fun ChatCardSection(
    notifyDialog: () -> Unit
) {
    val profileId = remember { UserIdManager.getUserId() }
    var currentTime by remember { mutableStateOf("") }
    var chatMessages by remember { mutableStateOf(listOf<ChatMessage>()) }
    var shouldScrollToBottom by remember { mutableStateOf(false) }
    var haveQuizMessage by remember { mutableStateOf<QuizMessage?>(null) }

    LaunchedEffect(Unit) {
        val notFilteredChatMessages = mutableListOf<ChatMessage>()
        FirebaseClient(firebaseConfig).getChatMessages().sortedBy { it.createdTime }.map {
            notFilteredChatMessages += it.toChatMessage()
        }
        chatMessages += addDateMessages(notFilteredChatMessages)
        chatMessages += getGreetingMessages()

        shouldScrollToBottom = true
    }

    DisposableEffect(Unit) {
        val interval = window.setInterval({
            val date = Date()
            val hours = date.getHours().toString().padStart(2, '0')
            val minutes = date.getMinutes().toString().padStart(2, '0')
            currentTime = "$hours:$minutes"
        }, 60000) // 1분마다 업데이트

        // 컴포넌트가 언마운트될 때 인터벌을 클리어합니다.
        onDispose {
            window.clearInterval(interval)
        }
    }

    Div(
        attrs = {
            style {
                width(100.percent)
                textAlign("center")
            }
            classes("fade-section")
        }
    ) {
        H2 {
            Text("방명록 \uD83D\uDDD2\uFE0F")
        }
    }
    JSSpacer(12.px)
    Div({
        style {
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Column)
            width(100.percent)
            height(100.vh)
            boxSizing("border-box")
            backgroundColor(Color("#d5e7f2"))
        }
        classes("fade-section")
    }) {
        ChatStatusBar(currentTime)
        ChatTopBar("문병학, 이홍주님의 웨딩 방명록\uD83D\uDC8C")
        ChatMessages(profileId,
            chatMessages,
            shouldScrollToBottom = shouldScrollToBottom,
            onScrollComplete = { shouldScrollToBottom = false },
            onScrollToBottom = { shouldScrollToBottom = true},
            notifyDialog = notifyDialog,
            onSendQuizMessage = { quizMessage ->
                MainScope().launch {
                    haveQuizMessage = quizMessage
                }
            }
        )

        MessageTextField(profileId, haveQuizMessage, { shouldScrollToBottom = true }, notifyDialog) { inputMessage ->
            if (haveQuizMessage != null) {
                haveQuizMessage = null
            }
            chatMessages += inputMessage
            MainScope().launch {
                if (inputMessage is QuizMessage) {
                    FirebaseClient(firebaseConfig).addChatMessage(inputMessage.toChatMessageDomain())
                } else {
                    FirebaseClient(firebaseConfig).addChatMessage(inputMessage.toChatMessageDomain())
                }
            }
        }
    }
}

@Composable
fun ChatMessages(
    myId: String,
    chatMessages: List<ChatMessage>,
    shouldScrollToBottom: Boolean,
    onScrollComplete: () -> Unit,
    onScrollToBottom: () -> Unit,
    notifyDialog: () -> Unit,
    onSendQuizMessage: (QuizMessage) -> Unit
) {
    val chatContainerId = remember { "chat-container-0" }

    LaunchedEffect(shouldScrollToBottom) {
        if (shouldScrollToBottom) {
            delay(200) // DOM 업데이트를 위한 짧은 지연
            scrollToBottom(chatContainerId)
            onScrollComplete()
        }
    }

    Div({
        id(chatContainerId)
        style {
            flex(1)
            overflowY("scroll")
            padding(0.px)
        }
    }) {
        Div({
            style {
                display(DisplayStyle.Flex)
                flexDirection(FlexDirection.Column)
                alignItems(AlignItems.FlexStart)
            }
        }) {
            chatMessages.forEach {
                if (it is DateMessage) {
                    DateMessageUI(it)
                } else if (it.profileId == myId) {
                    SentMessage(it)
                } else {
                    ReceivedMessage(it, onClick = {
                        onScrollToBottom()
                    }, onSendQuizMessage, notifyDialog)
                }
            }

        }
    }
}

private fun scrollToBottom(elementId: String) {
    val element = document.getElementById(elementId) as? HTMLElement
    element?.scrollTo(0.0, element.scrollHeight.toDouble())
}


@Composable
fun MessageTextField(
    myId: String,
    haveQuizMessage: QuizMessage?,
    onMessageSent: () -> Unit, // 새로운 콜백 추가
    notifyDialog: () -> Unit,
    onChatMessageListener: (ChatMessage) -> Unit
) {
    var inputText by remember { mutableStateOf("") }
    var nicknameText by remember { mutableStateOf("") }

    Div({
        style {
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Column)
            padding(16.px)
            backgroundColor(Color.white)
        }
    }) {
        // 닉네임 입력 필드
        Input(type = InputType.Text, attrs = {
            value(nicknameText)
            placeholder("닉네임 입력...")
            style {
                width(100.percent - 16.px)
                marginBottom(8.px)
                padding(8.px)
                borderRadius(8.px)
                border(0.75.px, LineStyle.Solid, Color.gray)
            }
            onInput {
                nicknameText = it.value
            }
        })

        Div({
            style {
                display(DisplayStyle.Flex)
                alignItems(AlignItems.Center)
            }
        }) {
            // 메시지 입력 필드
            if (haveQuizMessage != null) {
                Input(type = InputType.Text, attrs = {
                    value(inputText)
                    readOnly()
                    style {
                        flex(1)
                        padding(8.px)
                        paddingLeft(36.px) // 이미지 공간 확보
                        backgroundImage("url('${haveQuizMessage.savedQuizImage}')") // 이미지 경로
                        backgroundRepeat("no-repeat")
                        backgroundPosition("8px center") // 이미지 위치 설정 (왼쪽에 배치)
                        borderRadius(8.px)
                        backgroundSize("200px 155px") // 이미지 크기 설정 (200x400)
                        height(155.px) // 배경 이미지의 높이인 400px에 맞춰 Input의 높이 설정
                        border(0.75.px, LineStyle.Solid, Color.gray)
                    }
                    onInput {
                        inputText = ""
                    }
                })
            } else {
                Input(type = InputType.Text, attrs = {
                    value(inputText)
                    placeholder("메시지 입력...")
                    style {
                        flex(1)
                        padding(8.px)
                        borderRadius(8.px)
                        border(0.75.px, LineStyle.Solid, Color.gray)
                    }
                    onInput {
                        inputText = it.value
                    }
                })
            }


            // 전송 버튼
            Button(attrs = {
                style {
                    marginLeft(8.px)
                    padding(8.px)
                    borderRadius(50.percent)
                    backgroundColor(Color("#ffeb3b"))
                    border(0.px)
                    cursor("pointer")
                }
                onClick {
                    if (inputText.isNotBlank() && nicknameText.isNotBlank()) {
                        onChatMessageListener.invoke(
                            ChatMessage(
                                inputText,
                                myId,
                                "empty",
                                nicknameText,
                                Date().getTime().toString()
                            )
                        )
                        inputText = "" // 전송 후 메시지 입력 필드 초기화
                        onMessageSent()
                    } else if (haveQuizMessage != null && nicknameText.isNotBlank()){
                        onChatMessageListener.invoke(
                            haveQuizMessage.apply {
                                content = "${nicknameText}님이 ${score.toInt() * 20}점을 맞았습니다. 축하해주세요!"
                                profileName = nicknameText
                            }
                        )
                    } else {
                        notifyDialog()
                    }
                }
            }) {
                Text("전송")
            }
        }
    }
}

fun formatTime(timestamp: String): String {
    val timeInMillis = timestamp.toDoubleOrNull() ?: return "invalid"
    val date = Date(timeInMillis)
    val hours = date.getHours()
    val minutes = date.getMinutes().toString().padStart(2, '0')
    val period = if (hours < 12) "오전" else "오후"
    val formattedHour = if (hours % 12 == 0) 12 else hours % 12

    return "$period $formattedHour:$minutes"
}