package com.damda.webapp.views.sections.main

import androidx.compose.runtime.Composable
import com.damda.invitation.themes.Colors
import com.damda.webapp.views.layouts.Card
import com.damda.webapp.views.layouts.JSSpacer
import com.damda.webapp.views.layouts.VerticalLayout
import com.damda.webapp.views.texts.ContractContentSection
import com.damda.webapp.views.texts.TextSectionPresentation
import com.damda.webapp.views.themes.strokeCardStyle
import com.damda.webapp.views.themes.toCssColor
import kotlinx.browser.window
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Text

@Composable
fun ContractList() {
    Div(
        {
            classes("fade-section")
            style {
                padding(4.px)
                boxSizing("border-box")
            }
        }

    ) {
        Div(
            attrs = {
                style {
                    width(100.percent)
                    display(DisplayStyle.Flex)
                    justifyContent(JustifyContent.Center)
                    alignItems(AlignItems.Center)
                    textAlign("center")
                }
            }
        ) {
            H2 {
                Text("연락처 \uD83D\uDCF1")
            }
        }
        JSSpacer(height = 16.px)
        ContractCardSection(
            childTextSection = TextSectionPresentation("ic_tuxedo.webp", "문병학", "신랑"),
            parentTextSection = TextSectionPresentation("ic_princess.webp", "성승희", "문병학의 어머니"),
            childOnCallClick = {
                window.location.href = "tel:01028500312"
            },
            childOnMessageClick = {
                val message = "결혼을 축하드립니다!"
                window.location.href = "sms:01028500312?body=$message"
            },
            parentOnCallClick = {
                window.location.href = "tel:01087382978"
            },
            parentOnMessageClick = {
                val message = "결혼을 축하드립니다!"
                window.location.href = "sms:01087382978?body=$message"
            }
        )
        ContractCardSection(
            childTextSection = TextSectionPresentation("ic_veil.webp", "이홍주", "신부"),
            parentTextSection = TextSectionPresentation("ic_princess.webp", "최순덕", "이홍주의 어머니"),
            childOnCallClick = {
                window.location.href = "tel:01064757541"
            },
            childOnMessageClick = {
                val message = "결혼을 축하드립니다!"
                window.location.href = "sms:01064757541?body=$message"
            },
            parentOnCallClick = {
                window.location.href = "tel:01089787541"
            },
            parentOnMessageClick = {
                val message = "결혼을 축하드립니다!"
                window.location.href = "sms:01089787541?body=$message"
            }
        )
    }
}
@Composable
fun ContractCardSection(
    childTextSection: TextSectionPresentation,
    parentTextSection: TextSectionPresentation,
    childOnCallClick: () -> Unit,
    childOnMessageClick: () -> Unit,
    parentOnCallClick: () -> Unit,
    parentOnMessageClick: () -> Unit
) {
    Card (modifier = {
        width(100.percent)
        height(auto)
        paddingTop(16.px)
        paddingBottom(16.px)
        backgroundColor(Colors.backgroundColor.toCssColor())
    }, borderRadius = 12.px) {
        VerticalLayout {
            Div(
                {
                    style {
                        marginLeft(4.px)
                        marginRight(4.px)
                    }
                }
            ) {
                ContractContentSection(childTextSection, childOnCallClick, childOnMessageClick, true)
            }
            JSSpacer(height = 12.px)
            Div(
                {
                    style {
                        marginLeft(4.px)
                        marginRight(4.px)
                    }
                }
            ) {
                Card (modifier = {
                    marginTop(12.px)
                    marginBottom(12.px)
                    width(100.percent)
                    height(auto)
                    backgroundColor(Color("#f2f2f2"))
                    border(1.px, LineStyle.Solid, Color.lightgray)
                    borderRadius(12.px)
                }) {
                    ContractContentSection(parentTextSection, parentOnCallClick, parentOnMessageClick, false)
                }
            }
        }
    }
}