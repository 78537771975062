package com.damda.webapp.views.sections.main

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.damda.invitation.themes.Colors
import com.damda.webapp.views.layouts.Card
import com.damda.webapp.views.layouts.CardBody
import com.damda.webapp.views.layouts.JSSpacer
import com.damda.webapp.views.texts.TextInfoStyle
import com.damda.webapp.views.themes.toCssColor
import kotlinx.browser.window
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date

@Composable
fun GreetingCardSection() {
    Card(modifier = {
        width(auto)
        height(90.vh)
        display(DisplayStyle.Flex)
        justifyContent(JustifyContent.Center)
        alignItems(AlignItems.Center)
        textAlign("center")
    }, className = "fade-section") {
        CardBody(
            modifier = {
                width(auto)
                height(auto)
                boxSizing("border-box")  // 패딩을 요소 크기에 포함
            },
            content = {
                Div (
                    {
                        style {
                            padding(24.px)
                        }
                    }
                ){
                    H2 { Text("결혼하는 날") }
                    JSSpacer(height = 12.px)
                    listOf(
                        TextInfoStyle("\uD83D\uDD70\uFE0F 시간", "#000000", "500"),
                        TextInfoStyle("2024년 10월 20일, 오전 11시 30분", "#000000", "300"),
                    ).forEach { style ->
                        Div({
                            style { fontWeight(style.weight) }
                        }) {
                            if (style.text == "\n") {
                                Div(attrs = {
                                    style {
                                        height(28.px)
                                    }
                                })
                            } else {
                                Text(style.text)
                            }
                        }
                    }
                    JSSpacer(height = 24.px)
                    WeddingCalendar()
                    JSSpacer(height = 48.px)

                    CountdownTimer()
                }
            }
        )
    }
}

@Composable
fun CountdownTimer() {
    var remainingTime by remember { mutableStateOf(calculateRemainingTime()) }

    DisposableEffect(Unit) {
        val timerId = window.setInterval({
            remainingTime = calculateRemainingTime()
        }, 1000)

        onDispose {
            window.clearInterval(timerId)
        }
    }

    Div({
        style {
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Column)
            alignItems(AlignItems.Center)
        }
    }) {
        Div({
            style {
                display(DisplayStyle.Flex)
                justifyContent(JustifyContent.SpaceBetween)
                width(100.percent)
            }
        }) {
            TimerItem("Day", remainingTime.days)
            TimerItem("Hour", remainingTime.hours)
            TimerItem("Min", remainingTime.minutes)
            TimerItem("Sec", remainingTime.seconds)
        }
        P({
            style {
                fontSize(1.2.em)
                textAlign("center")
            }
        }) {
            Text("병학, 홍주의 결혼식이 ")
            Span({
                style {
                    color(Colors.pinkColor.toCssColor())
                    fontWeight("bold")
                }
            }) {
                Text("${remainingTime.days}")
            }
            Text("일 남았습니다.")
        }

    }
}

@Composable
fun TimerItem(label: String, value: Int) {
    Div({
        style {
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Column)
            alignItems(AlignItems.Center)
            margin(8.px)
        }
    }) {
        Div({
            style {
                fontSize(0.9.em)
                color(Color.lightgray)
                property("text-transform", "uppercase")
            }
        }) {
            Text(label)
        }
        Div({
            style {
                fontSize(1.5.em)
            }
        }) {
            Text(value.toString().padStart(2, '0'))
        }
    }
}

data class RemainingTime(val days: Int, val hours: Int, val minutes: Int, val seconds: Int)

fun calculateRemainingTime(): RemainingTime {
    val now = Date()
    val targetDate = Date("2024-10-20T11:30:00+09:00")
    val difference = targetDate.getTime() - now.getTime()

    val secondsTotal = (difference / 1000).toInt()
    val days = secondsTotal / 86400
    val hours = (secondsTotal % 86400) / 3600
    val minutes = (secondsTotal % 3600) / 60
    val seconds = secondsTotal % 60

    return RemainingTime(days, hours, minutes, seconds)
}