package com.damda.invitation.core

open class Event<out T>(private val content: T) {

    var hasBeenHandled = false
        private set // 외부에서 수정할 수 없도록 함

    /**
     * 이벤트가 처리되지 않은 경우에만 내용을 반환하고, 이후에는 null을 반환
     */
    fun getContentIfNotHandled(): T? {
        return if (hasBeenHandled) {
            null
        } else {
            hasBeenHandled = true
            content
        }
    }

    /**
     * 이벤트가 이미 처리된 경우에도 내용을 반환
     */
    fun peekContent(): T = content
}