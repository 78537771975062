package com.damda.webapp.views.images

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.damda.invitation.ui.viewmodels.GalleryItem
import com.damda.webapp.views.layouts.JSCircularProgressIndicator
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.gridTemplateColumns
import org.jetbrains.compose.web.css.gridTemplateRows
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.minHeight
import org.jetbrains.compose.web.css.minWidth
import org.jetbrains.compose.web.css.overflow
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.w3c.dom.HTMLImageElement

@Composable
fun ImageGallery(
    images: List<String>,
    onClickGallery: (GalleryItem, List<String>) -> Unit
) {
    Div(attrs = {
        style {
            display(DisplayStyle.Grid)
            gridTemplateColumns("repeat(3, 1fr)")
            gridTemplateRows("repeat(3, 1fr)")
            gap(2.px)
        }
    }) {
        images.forEachIndexed { index, imageUrl ->
            val borderRadius = when (index) {
                0 -> "16px 0 0 0"  // top-left
                2 -> "0 16px 0 0"  // top-right
                9 -> "0 0 0 16px"  // bottom-left
                11 -> "0 0 16px 0"  // bottom-right
                else -> "0"        // no rounding
            }

            Div(attrs = {
                style {
                    property("border-radius", borderRadius)
                    overflow("hidden")
                }
                onClick {
                    onClickGallery.invoke(GalleryItem(imageUrl), images)
                }
            }) {
                Img(src = imageUrl, attrs = {
                    style {
                        width(100.percent)
                        height(30.vw)
                        property("object-fit", "cover")
                        display(DisplayStyle.Block) // 여백 제거를 위해 block으로 설정
                        property("vertical-align", "top") // vertical-align 설정
                    }
                })
            }
        }
    }
}


@Composable
fun JSImage(
    url: String,
    attrs: AttrBuilderContext<HTMLImageElement>? = null
) {
    var isLoading by remember { mutableStateOf(true) }

    LaunchedEffect(url) {
        isLoading = false
    }

    Img(src = url, attrs = attrs)
}

@Composable
fun JSRoundedImage(
    url: String,
    borderRadius: CSSNumeric = 50.percent,
    contentDescription: String = "",
    size: Int = 42,
    borderColor: CSSColorValue = Color.black, // 윤곽선 색상
    borderWidth: CSSNumeric = 2.px // 윤곽선 두께
) {
    var isLoading by remember { mutableStateOf(true) }

    LaunchedEffect(url) {
        isLoading = false
    }

    Div({
        style {
            width(size.px)
            height(size.px)
            minWidth(size.px)
            minHeight(size.px)
            borderRadius(borderRadius)
            border {
                style = LineStyle.Solid
                color = borderColor
                width = borderWidth
            }
            overflow("hidden")

            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.Center)
            alignItems(AlignItems.Center)
        }
    }) {
        if (isLoading) {
            JSCircularProgressIndicator()
        } else {
            Div({
                style {
                    width(100.percent)
                    height(100.percent)
                    display(DisplayStyle.Flex)
                    justifyContent(JustifyContent.Center)
                    alignItems(AlignItems.Center)
                }
            }) {
                Img(
                    src = url,
                    alt = contentDescription,
                    attrs = {
                        style {
                            width(100.percent)
                            height(100.percent)
                            property("object-fit", "cover")
                        }
                    }
                )
            }
        }
    }
}