package com.damda.webapp.views.core

fun openMaps(latitude: Double, longitude: Double, label: String) {
    val encodedLabel = js("encodeURIComponent")(label) as String
    val geoUri = "geo:$latitude,$longitude?q=$latitude,$longitude($encodedLabel)"
    val googleMapsUrl = "https://www.google.com/maps/search/?api=1&query=$encodedLabel"

    // 모바일 기기 감지
    val userAgent = js("navigator.userAgent") as String
    val isMobile = userAgent.contains("Android") || userAgent.contains("iPhone")

    if (isMobile) {
        // 모바일에서는 geo: URI를 사용하여 기본 지도 앱 실행 시도
        js("window.location.href = geoUri")
    } else {
        // 데스크톱에서는 Google Maps 웹사이트 열기
        js("window.open(googleMapsUrl, '_blank')")
    }
}